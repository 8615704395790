// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-gemstone-js": () => import("/opt/build/repo/src/templates/gemstone.js" /* webpackChunkName: "component---src-templates-gemstone-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-autorka-js": () => import("/opt/build/repo/src/pages/autorka.js" /* webpackChunkName: "component---src-pages-autorka-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kameny-js": () => import("/opt/build/repo/src/pages/kameny.js" /* webpackChunkName: "component---src-pages-kameny-js" */),
  "component---src-pages-kontakt-js": () => import("/opt/build/repo/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-markety-js": () => import("/opt/build/repo/src/pages/markety.js" /* webpackChunkName: "component---src-pages-markety-js" */),
  "component---src-pages-projekty-js": () => import("/opt/build/repo/src/pages/projekty.js" /* webpackChunkName: "component---src-pages-projekty-js" */),
  "component---src-pages-success-js": () => import("/opt/build/repo/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

